.review_edit{
    line-height: auto;
    padding:0;
    .lableHeader-table .kBriZi{
        /*background-color: #F5F4FF !important;
        border-top: 1px solid #715AFF !important;
        border-left: 1px solid #715AFF !important;
        border-right: 1px solid #715AFF !important;
        height: 110px !important;
        */
    }
    .lableHeader-table .iudkJw{
        /*border-top: none;
        border-bottom: 1px solid #715AFF !important;
        border-left: 1px solid #715AFF !important;
        border-right: 1px solid #715AFF !important;
        */
    }
    .review-lable {
        margin-top:20px;
        margin-bottom:15px;
        border-right:1px solid #D3CFF8;
      .review-lable-main{
        .lable-header{
            height: 11px;
            width: 59px;
            color: #403788;
            font-size: 10px;
            letter-spacing: 0.83px;
            line-height: 11px;
        }
        .lable-header-value{
            height: 40px;
            width: 108px;
            color: #403788;
            font-size: 14px;
            font-weight: bold;
            /*cursor: pointer;
            word-break: break-all;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: all .2s linear;
            /*line-height: 20px;*/
            margin-top:1px;
            .lable-header-icon{
                display:inline;
            .lable-header-social{
                margin-top:2px;
                position: absolute;
                padding-left:6px !important;
                vertical-align: middle !important;
            }
            } 
        }
        .lable-header-least {
            height: 20px;
            width: 70px;
            color: #403788;
            font-size: 10px;
            line-height: 20px;
        }
      }
      .review-lable-rating{
        margin-top:10px;
        margin-bottom:15px;
        .lable-header{
            height: 11px;
            width: 59px;
            color: #403788;
            font-size: 10px;
            letter-spacing: 0.83px;
            line-height: 11px;
        }
        .lable-header-rating{
            height: 21px;
            width: 128px;
            color: #403788;
            font-size: 14px;
            font-weight: bold;
        }
        .lable-header-value{
            height: 20px;
            width: 108px;
            color: #403788;
            font-size: 12px;
            font-weight: bold;
            /*padding-top:10px;*/
            /*line-height: 20px;*/
        }
    }   
    }
    .review-lable-social{
        margin-top:20px;
        margin-bottom:15px;
        .review-lable-social-main{
            margin-top:10px;
            margin-bottom:15px;
            .lable-header{
                height: 11px;
                width: 59px;
                color: #403788;
                font-size: 10px;
                letter-spacing: 0.83px;
                line-height: 11px;
            }
            .lable-header-value{
                height: 40px;
                width: 108px;
                color: #403788;
                font-size: 14px;
                font-weight: bold;
                line-height: 20px;
                /*cursor: pointer;
                word-break: break-all;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: all .2s linear;
                /*padding-top:10px;*/
            }
            /*
            .lable-header-value:hover{
                overflow: visible; 
                height: 40px;
                white-space: normal; 
            }
            */
        }
        .review-lable-social-location{
            margin-top:10px;
            margin-bottom:15px;
            .lable-header{
                height: 11px;
                width: 59px;
                color: #403788;
                font-size: 10px;
                letter-spacing: 0.83px;
                line-height: 11px;
            }
            .lable-header-value{
                height: 20px;
                width: 108px;
                color: #403788;
                font-size: 14px;
                font-weight: bold;
                /*cursor: pointer;
                word-break: break-all;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: all .2s linear;
                margin-right: 5px;
                /*line-height: 20px;*/
            }
            /*
            .lable-header-value:hover{
                overflow: visible; 
                height: 40px;
                white-space: normal; 
            }
            */

        }
    }
    .lableHeader-table-response{
        width: 100vw; 
        max-width: 100%;
        /*overflow: hidden;*/
        /* overflow-x: auto; */
        white-space: nowrap;
        .lable-header-response-value{
            height: 20px;
            width: 108px;
            color: #403788;
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
        }
    }
    
    .lableHeader-table-response div:first-child div:first-child div.row {
        background-color: rgb(245, 244, 255) !important;
    
    }
    .lableHeader-table-response div:first-child div:first-child {
        width: 99.8%;
        display: inline-block;
        padding: 6px 0px 0px 10px !important;
        background: transparent !important;
    
    }
    .lableHeader-table-response .response-count {
        width: 5%;
        height: 300px;
        display: inline-block;
        background:#403788;
        border-bottom-right-radius: 50px 33px;
        border-top-right-radius: 50px 33px;
        text-align: center;
        color:#fff;
        right:-20px;
        top:0;
        z-index: 0;
        position: absolute;
        writing-mode: vertical-lr; 
        transform: rotate(180deg);
        font-size: 10px;
        font-family: Roboto;
        margin-top:60px;
        margin-right:5px;
        padding-left:10px;
    }
    .lableHeader-table-approval{
        .lable-header-response-value{
            height: 20px;
            width: 108px;
            color: #403788;
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
        }
    }
    
    .lableHeader-table-response .kBriZi{
        /*
        background-color: #F5F4FF !important;
        border-top: 1px solid #715AFF !important;
        border-left: 1px solid #715AFF !important;
        border-right: 1px solid #715AFF !important;
        */
    }
    .lableHeader-table-response .iudkJw{
        /*
        border-top: none;
        border-bottom: 1px solid #715AFF !important;
        border-left: 1px solid #715AFF !important;
        border-right: 1px solid #715AFF !important;
        */
    }
    .lableHeader-table-approval .kBriZi{
        /*
        background-color: #F5F4FF !important;
        border-top: 1px solid #715AFF !important;
        border-left: 1px solid #715AFF !important;
        border-right: 1px solid #715AFF !important;
        */
    }
    .lableHeader-table-approval .iudkJw{
        /*
        border-top: none;
        border-bottom: 1px solid #715AFF !important;
        border-left: 1px solid #715AFF !important;
        border-right: 1px solid #715AFF !important;
        */
    }   
    .review-box-content{
        height: 24px;
        width: 584px;
        color: #403788;
        font-size: 12px;
        line-height: 14px;
        padding-top:9px;
        margin-left:0px;
    }
    #response_textarea.jmDYBG{
        border-style: none; 
        border-color: Transparent; 
        overflow: auto;
        min-height: 327px;
        box-shadow: none;
        font-size: 15px;   
    }
    #response_textarea .eCDUOA{
       display:none; 
    }   

}

.share-review-block {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    z-index: 1;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    border-radius: 2px;
}
#ai_generated{
    height: 100px;
    width: 98% !important;
    margin-left: 12px;
    border: 1px solid #403788;
    box-shadow:  rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 8px;
    font-size: 12px;
    overflow: auto;
    min-height: 115px;
    margin-top: -20px;
    line-height: 14px;
    padding-left: 17px;
    padding-top: 0px !important;
    // bottom: 28px;
}
.ai_response{
    font-size: 16px;
    color: darkblue;
    font-weight: bold;
}
.rating_text{
    margin-left: 10px;
    margin-top: 14px;
}
#ai_response.cyqujk{
    width: 40%;
}
#ai_response.cyqujk{
    width: '25%';
     margin-left: '0px';
}
.star_rating{
    display: flex;
}
#star_rating.kVqvUW{
font-size:'20px';
}
#ai_response .cyqujk {
    width: '30%'; 
    margin-right: '0px';
    text-align: 'initial';
}
.rating{
    div{
     padding: 0px 0px 0px 10px !important;
    }
    width: 25%;
}
.aiResponseHeader{
  display: flex !important;
  justify-content: space-between;
}
.ratingValidation{
    color: red;
}
#useAiButton{
    width: 126px;
    margin-right: 0px;
    text-align: justify;
    height: 40px;
    margin-top: 4px;
    font-size: 14px;
}
.ai_errorLabel{
    color: red;
    margin-top: 4px;
}