.opinion-text, .feature-text {
	height: 19px;
	width: 103px;
	color: #403788;
	font-family: Roboto;
	font-size: 16px;
	line-height: 19px;
}
.resolve-pronoun-text {
    height: 19px;
	color: #403788;
	font-family: Roboto;
	font-size: 16px;
	line-height: 19px;
}
.option-mask, .feature-mask {
	box-sizing: border-box;
    min-height: 175px;
    height: auto;
	// width: 850px;
	border: 1px solid #D3CFF8;
	border-radius: 6px;
	background-color: #FFFFFF;
    // box-shadow: 0 10px 40px -10px rgba(189,189,189,0.5);
    margin-top: 10px;
}
.opinion-sentence-block , .feature-sentence-block{
    display: flex;
    min-height: 175px;
    height: auto;
    // div {
    //     min-height: 175px;
    //     height: auto;
    // }
        color: #403788;
        font-family: Roboto;
        font-size: 16px;
        line-height: 29px;
    .opinion-sentence , .feature-sentence
    {
        border-right: 1px solid #D3CFF8;
        padding: 20px;

    }
    .opinion-sentence-input, .feature-sentence-input {
        margin-top: 55px;
    }
    .no-opinion-found , .no-feature-found {
        height: 16px;
    /* width: 112px; */
    color: #4A90E2 !important;
    font-family: Roboto;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    border: unset;
    background-color: #ffff;
    }

}
.opinion-seperator {
    margin: 15px -20px;
    border-bottom: 1px solid #D3CFF8;
}
.picked-items {
    color: #403788;
    font-family: Roboto;
    cursor: pointer !important;
    mark {
        color: #403788;
        font-family: Roboto;
    }
}
.surveyQuestionItems {
    .picked-items {
        color: #E74C3C;
        font-family: Roboto;
        cursor: pointer !important;
        mark {
            color: #E74C3C;
            font-family: Roboto;
        }
    }
}
::-moz-selection { /* Code for Firefox */
    color: red;
    background: yellow;
  }
  
::selection {
    color: red;
    background: yellow;
}
.newHighLightColor {
    background: green;
}
.disable-select {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}