.support-request-panel {
    display: flex;
    // margin-right: 60px;
    margin: 0px !important;
    align-items: center;
    width: 100% !important;
    min-height: 84px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 0 0 #DAD4FC;
    padding: 20px;
    justify-content: flex-end;
    .support-request-filters {
        display: flex;
        justify-content: flex-end
    }
    .page-title {
        height: 28px;
        width: 100%;
        color: #403788;
        font-family: Roboto;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 28px;
    }
    [data-theme=MozartSelectField] {
        margin: 0px 20px 0px;
    }
}
.support-task-table {
    padding: 20px;
    margin: 3px 0px 0px 0px !important;
    [data-theme=MozartTable] {
        width: 100% !important;
    }
    table th {
        font-size: 12px !important;
        // text-align: center;
        max-width: 50px;
        overflow-wrap: break-word;
    }
    #mozart_table_header_id {
        display: contents;
    }
    tr td {
        // first-child { background: yellow; }
        font-size: 14px !important;
        color:#403788;
        // text-align: center;
        max-width: 50px;
        overflow-wrap: break-word;
        letter-spacing: 0;
        line-height: 16px;
        font-family: Roboto;
        .long-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;
        }
    }
    tr>td:nth-child(1){ 
        padding-left: 25px;
    }
    table th:nth-child(1){ 
        padding-left: 25px;
    }
    table th:nth-child(10){ 
        padding-left: 35px;
    }
}
.assignment-popup-panel {
    .task-details-items {
        border-right: 1px solid #403788;
        padding: 0px 10px 0px 10px;
        height: auto;
        width: 14%;
        .task-details-label {
            height: 14px !important;
            color: #403788 !important;
            font-size: 14px !important;
            letter-spacing: 1px !important;
            line-height: 14px !important;
            text-transform: uppercase;
          }
          .task-details-value {
            height: auto;
            color: #403788;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 16px;
            margin-top: 5px;
          }
    }
    
    .no-border-required {
        border: none !important;
    }
}
.new-responder .task-details-label {
  height: 14px !important;
  color: #403788 !important;
  font-size: 14px !important;
  letter-spacing: 1px !important;
  line-height: 14px !important;
  text-transform: uppercase;
  text-align: center;
}

.review-block, .response-block, .comment-block {
    margin-top: 25px;
    padding-left: 10px;
    .rating-block {
        display: flex;
    .task-details-label-star {
        height: 14px !important;
        color: #403788 !important;
        font-size: 14px !important;
        letter-spacing: 1px !important;
        line-height: 14px !important;
        text-transform: uppercase;
        float:left;
        margin-top: 5px;
        width: 45%;
        }
        .task-details-value-star {
        width: 50%;
        float:right;
        height: auto;
        font-size: 12px;
        margin-top: 5px;
        }
    }
    .task-details-label {
        height: 14px !important;
        color: #403788 !important;
        font-size: 14px !important;
        letter-spacing: 1px !important;
        line-height: 14px !important;
        text-transform: uppercase;
      }
      .task-details-value {
        height: auto;
        width: auto;
        color: #403788;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 5px;
        margin-bottom: 10px;
        padding-right: 10px;
      }
      .assign-btn-responder {
        height: 44px !important;
        width: 200px !important;
        padding-top: 8px;
      }

}
.assignee-list-label {
    height: 14px;
    color: #403788;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;
    min-height: 20px;
  }
  .sort-filter-block {
    display: flex;
    justify-content: flex-end;
    padding: 10px 30px 0px 0px !important;
  }
  .error-msg {
      color: red;
      text-align: center;
  }
  .reAssigned {
    text-decoration: line-through !important;
  }
