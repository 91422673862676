.review-sentiment-content-section {
	height: auto;
	width: 900px;
	border-radius: 5px;
    background-color: #F5F4FF;
    p {
        min-height: 96px;
        color: #4A4A4A;
        font-family: Roboto;
        font-size: 16px;
        line-height: 24px;
        padding: 15px;
    }
}

.review-sentiment-content-section-delete {

    color: #403788;
    font-family: Roboto;
    font-size: 18px;
    line-height: 21px;
    label {
      color: #403788 !important;
    font-family: Roboto;
    font-size: 18px;
    line-height: 21px;
    }
  }
.sentiments {
    margin-top: 12px;
}
    // #MozartModal{
    //     div {
    //         color: #403788;
    //         font-family: "Roboto Medium";
    //         font-size: 12px;
    //         letter-spacing: 1px;
    //         line-height: 14px;
    //     }

    // }
.reviewsSentimentsBlock {
    .rectangle {
        box-sizing: border-box;
        height: 75px;
        width: 900px;
        border: 1px solid #715AFF;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 10px 18px -8px rgba(149,149,149,0.28);
    }
    .sentence-card {
        box-sizing: border-box;
        height: 75px;
        border: 1px solid #715AFF;
                border-radius: 5px;
        background-color: #FFFFFF !important;
      width: 100%;
      display: flex;
      box-shadow: 0 10px 18px -8px rgba(149,149,149,0.28) !important;
      .sentence-card-left {
        box-sizing: border-box;
        height: 75px;
        border-right: 1px solid #D3CFF8;
        width: 10% !important;
        .number-circle-icon {
          padding-left: 24px;
          padding-top: 15px;
        }
      } 
      .sentence-card-right {
        box-sizing: border-box;
        height: 75px;
        border-left: 1px solid #D3CFF8;
        width: 10% !important;
        display: flex;
        padding: 15px !important;
      }
      .sentence-card-middle {
        box-sizing: border-box;
        height: 75px;
        width: 79% !important;
        padding : 15px !important;
      }
      .sentiment-count {
        // padding: 0px !important;
        display: block !important;
        min-width: 65%;
        padding-top: 3px;
      }
      .number-circle-icon div {
        padding-top: 8px !important;
      }
      .sentiment-sentence {
        height: 22px;
        color: #403788;
        font-family: "Roboto-Medium";
        font-size: 16px;
        line-height: 19px;
        margin-top: 7px;
      }
      .sentiment-sentence-title {
          height: 14px;
          // width: 86.9px;
          color: #4A4A4A;
          font-family: Roboto;
          font-size: 12px;
          letter-spacing: 0.5px;
          line-height: 14px;
          text-transform: uppercase;
        }
        .nlp-sentiment-count {
          height: 16px;
          color: #403788;
          font-family: Roboto;
          font-size: 14px;
          line-height: 16px;
        }
        .sentence-id {
          height: 15px;
          width: 95px;
          color: #4A4A4A;
          font-family: "Roboto-Medium";
          font-size: 13px;
          letter-spacing: 0.5px;
          line-height: 15px;
        }
    
    }
    }
