.ReportMonitorContainer {
  clear: both;
  .ReportMonitoringItems {
    box-sizing: border-box;
    cursor: pointer;
    height: 6em;
    width: 10em;
    border: 1px solid #D3CFF8;
    border-radius: 8px;
    background-color: #FFFFFF;
    margin: 8em auto;
    text-align: center;
    .PageIcon {
      color: #403788;
      font-size: 32px;
      position: relative;
      top: 0.5em;
    }
    .title {
      color: #403788;
      font-size: 16px;
      font-weight: bold;
      margin: 1em auto;
    }
  }
  .ReportMonitoringItems:hover {
    border: 1px solid #403788;
    background-color: #403788;
    box-shadow: 0 12px 14px -10px #403788;
    .PageIcon {
      color: white;
    }
    .title {
      color: white;
    }
  }
}