.response_status{
        height: 84px;
        background-color: #F5F4FF;
        box-shadow: 0 1px 0 0 #715AFF;
        border-top: 1px solid #715AFF;

    .response_status_client, .response_status_taskId{
        margin-top:22px;
        margin-bottom:22px;
     
       .response_status_client_title{
        height: 11px;
        width: 100px;
        color: #403788;
        font-size: 10px;
        letter-spacing: 0.83px;
        line-height: 11px;
       }
       .response_status_client_value{
        height: 16px;
        width: 180px;
        color: #403788;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        margin-top: 5px;
       }
       .response_status_client_line {
        box-sizing: border-box;
        height: 40px;
        width: 1px;
        border: 1px solid #403788;
      } 
    }
}