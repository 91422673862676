.date-filter {
    text-align: right !important;
    p {
        display: none !important;
    }
    padding-left: 0px;
    padding-right: 0px;
    font-size: 14px;
}
.priority-table-header-block {
    padding-bottom: 15px;
    padding-left: 20px;

}
.user-data-table {
    #mozart_table_header_id {
        display: contents;
    }
    thead {
        height: 14px;
        width: 34px;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 14px;
        text-align: right;
    }
    th, td{
        text-align: center;
    }
}
.date-filter-label {
    padding: 10px;
    color: #403788;
    max-width: fit-content;
    min-width: fit-content;
    text-align: justify;
    width: 15%;
}
.DateRangePicker {
    padding-left: 15px;
}
.date-filter {
    padding-left: 0px  !important;
    padding-right: 0px  !important;
    font-size: 13px  !important;
    text-align: justify;
    padding: 0px;
    [data-theme=MozartRadio] {
        float: left !important;
	div {
	  margin: 3px !important;
	}
      }
      .DateRangePicker {
          padding: 0px !important;
        //   margin-left: 1px !important;
          width: 224px !important;
          svg {
              width: 15px !important;
          }
      }
      .DateRangePickerInput {
          display: flex !important;
          align-items: center !important;
          width: 180px !important;
          font-size: 12px !important;
          border-radius: 0px !important;
      }
    [data-theme=MozartDatePicker] {
        // width: 67% !important;
        font-size: 13px !important;
        .DateInput_input_1 {
            padding: 5px;
            font-size: 13px !important;
        }
    }
}
.priority-filter {
    padding-left: 0px  !important;
    padding-right: 0px  !important;
    font-size: 14px  !important;
}
.custom-date-filter-btn {
    position: relative;
    width: 100%;
    height: 40px;
    outline: none;
    font-style: inherit;
    font-variant: inherit;
    font-weight: 400;
    font-stretch: inherit;
    font-size: 15px;
    line-height: inherit;
    font-family: inherit;
    border-radius: 5px;
    background-color: #FFF;
    border: 1px solid #715AFF;
    border-radius: 5px;
    padding: 10px 10px;
    color: #242424;
    display: flex;
    [data-theme=MozartIcon] {
        border: none;
        padding-top: 0px;
    }
}
