.sentance-sentiment-container {
  box-sizing: border-box;
  height: 122px;
  border: 1px solid #D3CFF8;
  border-radius: 8px;
  background-color: #FFFFFF;
  margin-top: 14px;
  margin-bottom: 8px;
  .sentence-sentiment-sub-block {
    display: flex;
    padding: 20px !important;
    .sentiment-block-items {
      height: 80px !important;
      box-sizing: border-box;
      border-right: 1px solid #D3CFF8;
      .sentiment-opinion-value {
        height: 19px;
        color: #715AFF;
        font-family: Roboto;
        font-size: 16px;
        line-height: 16px;
        padding-top: 12px;
      }
      .sentiment-opinion {
        height: 19px;
        color: #4A4A4A;
        font-family: Roboto;
        font-size: 16px;
        line-height: 19px;
      }
      .nlp-type-sentiment {
        height: 22px;
        border-radius: 11px;
        background-color: #EDFFDB;
        color: #417505;
        font-family: Roboto;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        padding-top: 3px;
      }
      .manual-type-sentiment {
        height: 22px;
        border-radius: 11px;
        background-color: #EDFFDB;
        color: #3B6883;
        font-family: Roboto;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        padding-top: 3px;
      }
    }
    .sentiment-block-items-category {
      height: 80px !important;
      box-sizing: border-box;
      border-right: 1px solid #D3CFF8;
      border-left: 1px solid #D3CFF8;
      display: flex;
    }
    .sentiment-block-items-actions {
      border: unset !important;
      text-align: center;
    }
  }
}

.negative-btn-text {
  height: 40px;
  width: 74px;
  color: #ffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
}
.neutral-btn-text {
  height: 16px;
  width: 62.5px;
  color: #ffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
}
.positive-btn-text {
  height: 16px;
  width: 66.7px;
  color: #ffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
}
.btn-negative {
    box-sizing: border-box;
    height: 24px;
    margin: 7px;
    border: 1px solid #FC544C;
    border-radius: 20px;
    background-color: #FFFFFF;
    color: #FC544C;
  }
.btn-neutral {
  box-sizing: border-box;
  height: 24px;
  margin: 7px;
  border: 1px solid #979797;
  border-radius: 20px;
  background-color: #FFFFFF;
}
.btn-positive {
  box-sizing: border-box;
  height: 24px;
  margin: 7px;
  border: 1px solid #47B300;
  border-radius: 20px;
  background-color: #FFFFFF;
}

.action-sentiment-circle-icon {
  display: flex;
  margin-top: 9px;
  // margin-left: 0px;
  margin-left: -12px;
   padding: 0px;
   div {
     padding-top: 3px;
   }
   div {
    // background-color: #715AFF;
    color: #ffffff;
    span.icon-Delete {
      background-color: #E66B6B !important;
    }
    .delete-icon-div {
      background-color: #E66B6B !important;
    }
  }
  .delete-icon-div {
    padding: 3px;
    div {
      background-color: #E66B6B !important;
    }
  }
  .save-icon-div, .reset-icon-div {
    padding: 3px;
    div {
      background-color: #715AFF;
    }
  }
}
