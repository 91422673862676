.menu-header-block {
    display: flex;
    margin-top: 25px;
    min-height: 84px;
    border-bottom: 1px solid #DAD4FC;
    width: 100%;
    .menu-header-title-block {
        font-size: 20px;
        padding-top: 25px;
        height: 28px;
        width: 268px;
        color: #403788;
        font-family: Roboto;
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
        padding-left: 40px;
    }
    .menu-header-button-block {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
        align-items: center;
        .action-btn-queue-assignment, .action-btn-queue-config, .action-btn-send-support{
            margin: 0px 20px;
            height: 40px;
            width: 150px;
            border-radius: 5px;
            background-color: #715AFF;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            padding-top: 5px;
        }
    }
    .breadcrumbs {
        height: 11px;
        width: 127.3px;
        color: #9B9B9B;
        font-family: Roboto;
        font-size: 10px;
        line-height: 11px;
        padding-top: 5px;
      }
}