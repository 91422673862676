.priority-chart-legend-block {
    display: flex;
    margin-top: 25px;
    height: 35px;
    color: #354052;
    font-family: Roboto;
    font-size: 10px;
    line-height: 11px;
    justify-content: flex-end;
    .legend-item {
        padding: 0px 20px 0px 15px;
    }
}

.legend-queue-size {
    .legend-queue-size-bullet {
        height: 12.7px;
        width: 12.71px;
        background-color: #403788;
        float: left;
        border-radius: 7px
    }
    .legend-queue-size-label {
        height: 11px;
        padding: 1px 0px 0px 15px !important;
      }
  }

  .legend-in-progress{
    .legend-in-progress-bullet {
        height: 12.7px;
        width: 12.71px;
        background-color: #715AFF;
        float: left;
        border-radius: 7px
    }
    .legend-in-progress-label {
        height: 11px;
        padding: 1px 0px 0px 15px !important;
      }
  }

  .legend-pending-approval{
    .legend-pending-approval-bullet {
        height: 12.7px;
        width: 12.71px;
        background-color: #D3CFF8;
        float: left;
        border-radius: 7px
    }
    .legend-pending-approval-label {
        height: 11px;
        padding: 1px 0px 0px 15px !important;
      }
  }
  
  .legend-success{
    .legend-success-bullet {
        height: 12.7px;
        width: 12.71px;
        background-color: #47B300;
        float: left;
        border-radius: 7px
    }
    .legend-success-label {
        height: 11px;
        padding: 1px 0px 0px 15px !important;
      }
  }

  .legend-in-support{
    .legend-in-support-bullet {
        height: 12.7px;
        width: 12.71px;
        background-color: #F76E27;
        float: left;
        border-radius: 7px
    }
    .legend-in-support-label {
        height: 11px;
        padding: 1px 0px 0px 15px !important;
      }
  }

  .legend-failed{
    .legend-failed-bullet {
        height: 12.7px;
        width: 12.71px;
        background-color: #FF0000;
        float: left;
        border-radius: 7px
    }
    .legend-failed-label {
        height: 11px;
        padding: 1px 0px 0px 15px !important;
      }
  }

