.delete-sentiment-circle-icon {
  padding: 15px;
  margin-left: 22px;
  color: #F5F4FF;
  div {
    padding-top: 4px;
    background-color: #E66B6B !important;
    color: #F5F4FF;
  }
}

.manual-sentiment-checked {
	height: 30px;
	width: 30px;
	background-color: #715AFF;
}
.manual-sentiment-reset {
	height: 30px;
	width: 30px;
	background-color: #715AFF;
}
.manual-sentiment-delete {
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    border: 1px solid #E66B6B;
    background-color: #E66B6B;
}
.QC-Btn-list {
  display: flex;
  padding-top: 26px !important;
}
.review-btn-block {
  text-align: right;
}
.review-btn {
	height: 40px !important;
	width: 126px !important;
	color: #606161 !important;
	font-family: Roboto !important;
	font-size: 14px !important;
	line-height: 16px !important;
  text-align: center !important;
  border-radius: 20px !important;
  padding-top: 5px !important;
}
.approve-btn {
	height: 40px !important;
	width: 250px !important;
	border-radius: 20px !important;
  background-color: #403788 !important;
  padding-top: 5px !important;
  // z-index: -1000 !important;
}
.sentiment-block-items-actions-manual {
  .action-sentiment-circle-icon {
    div {
      // background-color: #715AFF;
      color: #ffffff;
      span.icon-Delete {
        background-color: #E66B6B !important;
      }
      .delete-icon-div {
        background-color: #E66B6B !important;
      }
    }
    .delete-icon-div {
      padding: 3px;
      div {
        background-color: #E66B6B !important;
      }
    }
    .save-icon-div, .reset-icon-div {
      padding: 3px;
      div {
        background-color: #715AFF;
      }
    }
  }
}

.review-btn::before {
  background:unset !important;
  color: #FFF;
  border: unset !important;
}
.approve-btn::before {
  background:unset !important;
}
.sentimentBtnList::before {
  background:unset !important;
  color: #FFF;
  border: unset !important;
}
.sentimentBtnList {
  border: 1px solid #47B300;
}
.add-button-enable {
  display: block;
}
.add-button-disable {
  display: none;
}
.Select__menu {
  z-index: 20000 !important;
}
.category-select-list .Select__menu-list {
  min-height: 20px !important;
  max-height: 150px !important;
  overflow-y: auto;
}
.sub-category-select-list .Select__menu-list {
  min-height: 20px !important;
  max-height: 150px !important;
  overflow-y: auto;
}