
.priority-chart-block {
    margin: 25px 0px !important;
    width: 100%;
    div, .recharts-responsive-container {
        cursor:pointer;
    }
    .priority-chart {
        height: 300px;
        width: 100%;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(215,215,215,0.5);
        padding: 0px !important;

        .chart-header {
            height: 40px;
            border-radius: 5px 5px 0 0;
            background-color: #F5F4FF;
            width: 100%;
            padding: 10px;
            .title {
                height: 14px;
                width: 116.66px;
                color: #403788;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 1px;
                line-height: 14px;
                text-transform: uppercase;
              }
        }
    }
}
.priority-based-on-client , .priority-based-on-source {
    justify-content: center;
}
.recharts-surface {
    cursor: pointer !important;
}
