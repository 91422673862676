@font-face {
  font-family: Roboto;
  src: url('./static/fonts/roboto/Roboto-Regular.ttf');
}
@font-face {
  font-family: Roboto-Medium;
  src: url('./static/fonts/roboto/Roboto-Medium.ttf');
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
button:active{
  outline: none !important;
  background: none !important;
}

.binary_logo {
  padding: 5px 0 !important;
  width: 100%;
  z-index: 99;
  // max-width: 180px;
  .productNavigation {
    margin-right: 15px;
    padding-right: 15px !important;
    padding-left: 0;
    border-right: 1px solid #D3CFF8;
    width: auto;
  }
}

.binary_breadcrums {
  max-height: 30px;
  position: relative;
  display: block;
  top: -4px;
  width: 100%;
  left: 0px;
  z-index: 1;
  i {
    font-size: 0.6em !important;
    margin-top: 2px !important;
  }
}

.app {
  nav :global(.fa) {
    font-size: 1.5em;
    line-height: 20px;
  }
}

.logo{
  // margin-top: 16px;
  margin-top: 5px;
  padding-left: 0;
  width: 550px;
}

.binary_nav {
  margin-bottom: 0 !important;
  border-radius: 0px !important;
  padding: 0 0 10px 0;
  background: #FFF !important;
}

.appContent {
  margin: 85px 0; // for fixed navbar
  margin-top: 0px;
  min-height: 800px;
  // overflow-x: hidden;
  // overflow-y: hidden;
}

// STUPID BOOTSTRAP ISSUE
.navbar-nav { -webkit-backface-visibility: hidden; }
.dropdown { -webkit-backface-visibility: hidden; }
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus{
  background-color: #F8F8F8;
  -webkit-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Click-me {
  margin: 50px 0;
}
.Edit-panel {
  min-height: 200vh;
}
.header-box {
    height: 72px;
    // width: 1440px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 24px -11px rgba(0,0,0,0.5);
}
.header-box-profile {
  float: left;
  text-align: right;
  padding-top: 15px;
  padding-left: 7px !important;
  display: flex;
}
.header-box-user-queue-list {
  float: left;
    padding-top: 15px;
}
.header-box-logo {
  float: left;
  padding-left: 0px !important;
}
.navbar-brand p {
  font-size: 16px !important;
}
.icon-Information {
  font-size: 30px !important;
}
.headroom-wrapper {
  height: auto !important;
}
// Bootstrap override css import from node modules
@import './../node_modules/rc-calendar/assets/index.css';
@import './../node_modules/rc-time-picker/assets/index.css';
// @import './../node_modules/react-input-range/dist/react-input-range.min.css';
@import './../node_modules/cropperjs/dist/cropper.css';
// @import './../node_modules/react-tagsinput/react-tagsinput.css';
@import './../node_modules/react-virtualized/styles.css';
@import './../node_modules/react-dates/lib/css/_datepicker.css';