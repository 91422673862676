.home {
  .header {
    text-align: center;
    .home-cotainer-logo{
      padding: 50px;
      text-align: center;
    }
    .title {
      padding: 50px;
      text-align: center;
      height: 24px;
      width: 250px;
      color: #403788;
      font-size: 20px;
      font-weight: bold;
      line-height: 45px;
    }
  }
  .page-box {
    text-align: center;
    margin-top:80px;
    .label {
      border: none;
      font-size: 22px;
      font-weight: 200;
      line-height: 35px;
      color: #403788;
      text-align: center;
    }
  }
  .pages {
    align-items: center;
    justify-content: center;
  }
}