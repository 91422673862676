.queue-user-card {
  .queue-header{
      /*width: 1282px;
      margin-top:25px;
      border-radius: 27.5px;
      background-color: #403788;
      box-shadow: 0 10px 18px -12px #4A4A4A;
      */
    .queue-header-primary{
      color:#fff;
      padding:14px;
      text-align: center;
      border-radius: 55px;
      /*border-right: 1px solid #D3CFF8;*/

    }
    .queue-header-last{
      color:#fff;
      padding:14px;
      text-align: center;
    }
  }
  .queue-status {
     height: 42px;
     /*width: 1282px;*/
      margin-top: 10px;
      /*margin-top:12px;
      padding: 0px;
      border-bottom: 1px solid #D3CFF8;
      */
      .queue-status-client{
        color: #403788;
        font-size: 14px;
        padding-left: 101px;
      
    }
      .queue-status-high{
        color: #403788;
        font-size: 14px;
        padding-left: 101px;
    
    }
      .queue-status-medium{
        color: #403788;
        font-size: 14px;
        padding-left: 114px;
      
    }
    .queue-status-normal{
      color: #403788;
      font-size: 14px;
      padding-left: 111px;
    
  }

      .queue-status-last{
        display: inline-block;
        color: #403788;
        font-size: 14px;
        text-align: center;
        padding-left: 98px;
        .bgWuNB{
          display: inline-block;
        }
    }
  }
}
#mozart_table_header_id_sticky{
  border-radius: 55px !important;
  /*width: 1282px !important;*/
  text-align: center !important;
  margin-left: 26px !important;
}
