.queue-assignment {
    .lable-header{
        color: #403788;
        font-size: 24px;
        font-weight: bold;
        padding:16px;
        margin-top: 6px;
        display: inline-block;
    }
    .lable-header-button{
        display: inline-block;
        width: 500px;
        text-align: right;
        .lable-header-cancel{
            display: inline-block;
            width:150px;
            height: 50px;
            padding:7px;
            vertical-align: middle;
            cursor: pointer;
            .lable-header-cancel1{
                margin-bottom: 5px;
                font-size: 14px;
                color: #403788;
            }
        }
        .lable-header-save{
        display: inline-block;
        margin-right: 11px;
        width:150px;
        height: 50px;
        padding: 25px;
        }
    }
  }