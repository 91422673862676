.filterForm {
  margin-bottom: 4em;
  .filterFormHeader {
    font-size: 28px;
    padding-bottom: 31px;
    text-align: center;
    color: #403787;
  }
  .reportStatusHeader {
    color: #53479D;
    font-size: 16px;
  }
  .boxArea {
    height: 300px;
    width: 200px;
    background: #fff;
    // border: 1px solid black;
    box-shadow: 0px 1px 5px 0px #6c757d;
  }
  .userContainer {
    .userHeader {
      color: #403788;
      font-size: 16px;
    }
  }
  .moniterFilterDateRange {
    margin: 2em 0;
    div[data-theme=MozartRadio] {
      float: left;
    }
  }
}
