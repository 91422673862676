.Responser {
    .header {
      color: #715AFF;
      .title {
        margin: 50px;
        padding: 50px;
        text-align: center;
      }
    }
    .lableHeader{
      margin-top:21px;
        margin-bottom: 19px;
        color: #403788;
        font-size: 24px;
        font-weight: bold;
        /*float:left;*/
        padding:0;
    }
    .template_filter{
      margin-top:19px;
      margin-bottom: 19px;
      .template-filter-box{
        margin-top: 8px;
      }
      .template_filter_lable{
        height: 16px;
        width: 112.11px;
        color: #403788;
        font-size: 16px;
        font-weight: 490;
        line-height: 16px;
        margin-top: 15px;
        padding-left: 88px;
      }
      .select-box-res{
        padding:0px;
      }
    }
    .edit-submit{
    margin-top:20px;
   }
   .button-container{
    float: right;
    margin-right: 10px;
      .send-button-container{
        width:132px;
        height: 40px;
        color: '#606161';
        padding-left: 5px;
      }
      .approve-button-container{
        margin-right: 12px;
        width:200px;
      }
      .approve-button-container .dftLGL{
      width:150px !important;
      float:right;
      }
  }
  }