.custom_email_select {
    margin-bottom: 15px;
    .Select__placeholder {
        padding: 10px 5px;
    }
}
.share-form {
    min-height: 380px;
}
.email-validaion-error {
    color: red;
    margin-bottom: 10px;
    margin-top: -16px;
    text-align: left;
    width: 100%;
    font-size: 11px;
    padding: 2px;
}

input#subject + div, #message + div {
    text-align: left;
    width: 100%;
    font-size: 11px;
    padding: 2px;
}