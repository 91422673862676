* {
  font-family: 'Roboto';
}
.monitorHeader{
    clear: both;
    margin-top: 60px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 40px;
    box-shadow: 0px 1px 5px 0px #6c757d;
    .infoBlock{
        padding: 10px;
        background-color: #fff;
        border-right: 1px solid #d7d4f8;
    }
    .infoBlock:last-child {
        border: none;
    }
    .infoBlock:nth-last-child(2) {
        border: none;
    }
    .infoBlockTitle{
        font-size: 14px;
        color: #403788;
        font-family: 'Roboto';
        .filterpaddingIcon{
            padding-left: 10px; 
            padding-top: 5px;
        }
    }
    .infoBlockTitleValue{
        font-size: 14px;
        color: #403788;
        font-family: 'Roboto';
        font-weight: bold;
    }
}
.filterModal {
  z-index: 999 !important;
}
.PageBlock{
  margin-top: 30px;
  table tbody tr {
  border-left: 30px solid white;
  border-right: 30px solid white;
  border-bottom: none;
  }
  table tbody tr:nth-child(even) td {background: #FFFFFF}
  table tbody tr:nth-child(odd) td {background: #F5F4FF}
  clear: both;
  .cellview {
      border-right: 1px solid #D3CFF8;
      height: auto;
      color: #403788;
      font-size: 14px;
      letter-spacing: 0; 
      line-height: 16px; 
      text-align: left;
      padding-left: 23px;
  }
  .cellview:last-child{
      border: none;
  }
  .filterColumn{
    display: inline-block;
  }
}
.ActionButton {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}
