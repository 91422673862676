.queue-config-card {
    .queue-header{
        /*width: 1282px;
        margin-top:25px;
        border-radius: 27.5px;
        background-color: #403788;
        box-shadow: 0 10px 18px -12px #4A4A4A;
        */
      .queue-header-primary{
        color:#fff;
        padding:14px;
        text-align: center;
        border-radius: 55px;
        /*border-right: 1px solid #D3CFF8;*/

      }
      .queue-header-last{
        color:#fff;
        padding:14px;
        text-align: center;
      }
    }
    .queue-status {
       height: 76px;
        /*width: 1282px;*/
        margin-top: 10px;
        /*margin-top:12px;
        padding: 0px;
        border-bottom: 1px solid #D3CFF8;
        */
        .queue-status-client{
            color: #403788;
            font-size: 14px;
            padding-left: 82px;
          
        }
        .queue-status-high{
          color: #403788;
          font-size: 14px;
          padding-left: 77px;
          margin-top: 15px;
      
      }
        .queue-status-medium{
          color: #403788;
          font-size: 14px;
          padding-left: 71px;
          margin-top: 15px;
        
      }
      .queue-status-normal{
        color: #403788;
        font-size: 14px;
        padding-left: 67px;
        margin-top: 15px;
      
    }
    .queue-status-approv{
      color: #403788;
      font-size: 14px;
      padding-left: 89px;
      margin-top: 15px;
    
  }

        .queue-status-last{
          display: inline-block;
          color: #403788;
          font-size: 14px;
          text-align: center;
          padding-left: 64px;
          margin-top: 15px;
          .bgWuNB{
            display: inline-block;
          }
      }
    }
  }
