.pull-right {
  float: right !important;
}
.binary_container {
  margin-top : 0px !important;
}
.documentHeaderBlock {
    height: 113px;
    background-color: #F5F4FF;
  }
  .documentHeaderBlockParentDoc {
    display: flex;
    padding-top: 15px;
    width: 100% !important;
  }
  .documentHeaderBlockParent {
    display: flex;
    padding-top: 15px;
    min-height: 75px;
    width: 1313px;
    height: auto;
    border-radius: 5px;
    background-color: #403788;
    box-shadow: 0 10px 18px -12px #4A4A4A;
    margin-left: 40px;
    width: 100% !important;
    margin-top: 14px !important;
  }
  .documentHeaderLeftBlockParent {
    display: flex;
  }
  .documentHeaderTitle {
    height: 24px;
    min-width: 89.3px;
    color: #403788;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 24px;
    text-transform: uppercase;
  }
  .documentHeaderValue {
    height: 24px;
    min-width: 104px;
    color: #403788;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 24px;
  }
  .btn-delete-document {
      box-sizing: border-box;
      height: 32px;
      width: 175px;
      border: 1px solid #715AFF;
      border-radius: 5px;
      p {
          height: 16px;
          // width: 124.4px;
          color: #606161;
          font-family: Roboto;
          font-size: 14px !important;
          line-height: 16px !important;
          padding: 8px 10px 15px 10px !important;
      }
      span {
        border: unset !important;
        color: #E66B6B !important;
      }
  }
  .btn-toggle-document {
    box-sizing: border-box;
    height: 32px;
    width: 175px;
    border: 1px solid #715AFF;
    border-radius: 5px;
    p {
        height: 16px;
        // width: 124.4px;
        color: #606161;
        font-family: Roboto;
        font-size: 14px !important;
        line-height: 16px !important;
        padding: 8px 10px 15px 10px !important;
    }
    span {
      border: unset !important;
      color: #715AFF !important;
    }
}
  .btn-refer-document {
    box-sizing: border-box;
    height: 32px;
    width: 155px;
    border: 1px solid #715AFF;
    border-radius: 5px;
    p {
        height: 16px;
        width: 124.4px;
        color: #606161;
        font-family: Roboto;
        font-size: 14px !important;
        line-height: 16px !important;
        padding: 8px 10px 15px 10px !important;
    }
    span {
      border: unset !important;
      color: #715AFF !important;
    }
  }
  // Content Header Style

  .content-header-parent {
    // height: 75px;
    // width: 1313px;
    // border-radius: 5px;
    // background-color: #403788;
    // box-shadow: 0 10px 18px -12px #4A4A4A;
    // margin-left: 40px;
    width: 96% !important;
    margin-top: 14px !important;
  }
  .contentHeaderLeftBlockParent {
    display: flex;
    padding-left: 0px;
  }
  .contentHeaderTitle {
    color: #FFFFFF;	
    font-family: "Roboto-Medium";
    font-size: 12px;	
    letter-spacing: 0.5px;	
    line-height: 24px;
    text-transform: uppercase;
  }
  .contentHeaderTitleSource {
    color: #FFFFFF;	
    font-family: "Roboto-Medium";
    font-size: 12px;	
    letter-spacing: 0.5px;
    line-height: 24px;
    text-transform: uppercase;
    padding-left:8px;
  }
  .contentHeaderValueSource, .contentHeaderValueSource a{
    color: #FFFFFF;	font-family: Roboto; padding-left:5px;	font-size: 14px;	font-weight: bold;	letter-spacing: 0.5px;	line-height: 24px;
  }
  .contentHeaderValue, .contentHeaderValue a{
    color: #FFFFFF;	font-family: Roboto;	font-size: 14px;	font-weight: bold;	letter-spacing: 0.5px;	line-height: 24px;
  }
  .nlp-status, .nlp-status-disable {
      height: 32px;
      width: 78px;
      border-radius: 16px;
      background-color: #FFFFFF;
      padding: 5px 12px;
  }
  .nlp-status-text {
    height: 16px;
    width: 27px;
    color: #776BB2;
    font-family: Roboto;
    font-size: 14px;
    line-height: 16px;
  }
  .nlp-status-icon {
    padding: 0px 6px 0px 15px;
    color: #403788;
  }
  .nlp-status-disable {
    box-sizing: border-box;
    height: 32px;
    width: 78px;
    border: 1px solid #776BB2;
    border-radius: 16px;
    text-align: center;
  }
  .nlp-status-disable {
    color: #776BB2;
    background-color: #403788; 
    .nlp-status-text {
      color: #776BB2;
    }
  }
  .documentContent {
    min-height: 72px;
    // width: 1263px;
    color: #4A4A4A;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    padding: 30px;
  }
  .documentContentBlock {
    box-sizing: border-box;
    padding-bottom: 30px;
    border-bottom: 1px solid #715AFF;
    margin-left: 40px !important;
    position: sticky; /* Allocates space for the element, but moves it with you when you scroll */
    top: 0; /* specifies the start position for the sticky behavior - 0 is pretty common */
    width: 100%;
    padding: 5px 0 5px 15px;
    color: white;
    margin: 0;
  }
  body {
    overflow-y: auto;
    margin: 0;
  }
  .sentence-list-block {
    width: 100%;
    min-height: 150vh;
    padding: 10px;
  }

  .sentence-list-block {
    padding-top : 30px !important;
    padding-left : 0px !important;
    padding-right: 0px !important;
    margin-left: 40px !important;

  }

  .survey-question-btn {	height: 30px;	width: 137px;	border-radius: 20px;	background-color: #EFF3F8; border: none;}
  .survey-question-div { padding-bottom: 15px !important; display: flex;}

  #documentContentBlock {
    position: sticky;
    top: 0px;
    z-index: auto;
  }