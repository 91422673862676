.sentence-card {
  box-sizing: border-box;
  height: 75px;
  border: 1px solid #D3CFF8;
  border-radius: 5px;
  background-color: #F5F4FF;
  box-shadow: 0 10px 18px -8px rgba(149,149,149,0.28);
  width: 100%;
  display: flex;
  .sentence-card-left {
    box-sizing: border-box;
    height: 75px;
    border-right: 1px solid #D3CFF8;
    width: 5% !important;
    .number-circle-icon {
      padding-left: 24px;
      padding-top: 15px;
      div {
        background-color: #FFFFFF;
        border: 1px solid #715AFF;
      }
     
    }
  } 
  .sentence-card-right {
    box-sizing: border-box;
    height: 75px;
    border-left: 1px solid #D3CFF8;
    width: 10% !important;
    display: flex;
    padding: 15px !important;
    .number-circle-icon {
      div {
        background-color: #715AFF;
                border: 1px solid #715AFF;
                color: #ffffff;
      }
     
    }
  }
  .sentence-card-middle {
    box-sizing: border-box;
    height: 75px;
    width: 85% !important;
    padding : 10px !important;
  }
  .sentiment-count {
    // padding: 0px !important;
    display: block !important;
    min-width: 65%;
    padding-top: 3px;
  }
  .number-circle-icon div {
    padding-top: 4px !important;
  }
  .sentiment-sentence {
    height: 22px;
    color: #403788;
    font-family: "Roboto-Medium";
    font-size: 16px;
    line-height: 19px;
    margin-top: 7px;
  }
  .sentiment-sentence-title {
    height: 14px;
    // width: 86.9px;
    color: #4A4A4A;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 14px;
    text-transform: uppercase;
  }
  .nlp-sentiment-count {
    height: 16px;
    color: #403788;
    font-family: Roboto;
    font-size: 14px;
    line-height: 16px;
  }
  .sentence-id {
    height: 15px;
    width: 95px;
    color: #4A4A4A;
    font-family: "Roboto-Medium";
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 15px;
  }
}
